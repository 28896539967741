export const HOVER_TAP_MOTION = {
  whileHover: {
    scale: 1.02,
  },
  whileTap: {
    scale: 0.95,
  },
  transition: { type: 'spring', stiffness: 200 },
};
export const HOVER_TAP_MOTION_SMALL = {
  whileHover: {
    scale: 1.005,
  },
  whileTap: {
    scale: 0.995,
  },
  transition: { type: 'spring', stiffness: 200 },
};

export const MAIN_NAV_MOTION = {
  initial: { y: -10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  transition: {
    type: 'spring',
    mass: 0.6,
    stiffness: 75,
    delay: 0.1,
  },
};
