import React, { FC, useRef } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import Navigation from './Navigation';
import useSiteConfigQuery from '../../../hooks/queries/useSiteConfigQuery';
import { useScrollDirection } from '../../../hooks/useScrollDirection';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { useNavigationState } from '../../../context/NavigationContext';
import { MAIN_NAV_MOTION } from '../../../constants/motion';

import Sidebar from './Navigation/Sidebar';
import { useModal } from '../../../context/ModalContext';

const Header: FC = () => {
  const { logo } = useSiteConfigQuery();
  const { pathname } = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const scrollDirection = useScrollDirection();
  const { open: sidebarOpen, closeModal: closeSidebar } = useModal();
  const { dropdownOpen } = useNavigationState();
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const handleClickOutside = () => {};
  useOnClickOutside(headerRef, handleClickOutside);

  const isHome = pathname === '/';
  const isEnglish = pathname.includes('/en');

  return (
    <header
      ref={headerRef}
      style={{
        transform: scrollDirection === 'down' ? `translateY(-100%)` : 'translateY(0)',
      }}
      className={clsx(
        'transition-transform ease-in-out duration-200 top-0 z-40 relative',
        isHome ? 'bg-gradient-to-b from-gray-900 lg:fixed bg-navy-blue lg:left-0 lg:right-0' : 'bg-navy-blue sticky',
        isHome && sidebarOpen && 'bg-navy-blue',
        isHome && !dropdownOpen && 'bg-transparent',
        isHome && dropdownOpen && 'bg-navy-blue'
      )}
    >
      <div>
        <div className="items-center justify-between mx-auto h-24 md:h-28 flex flex-col lg:flex-row z-10 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl">
          <motion.div
            {...MAIN_NAV_MOTION}
            className="flex justify-between h-24 md:h-28 lg:h-full min-h-full  w-full lg:w-auto"
          >
            <Link to="/" className="pl-6 sm:pl-6 md:pl-6 lg:pl-5 2xl:pl-6" onClick={() => closeSidebar()}>
              <Image
                {...logo}
                width={180}
                alt="logo"
                style={{
                  width: '180px',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Link>
            {isMobile && <Sidebar />}
          </motion.div>

          {!isMobile && <Navigation />}
        </div>
      </div>
    </header>
  );
};

export default Header;
