import React, { FC, ReactNode } from 'react';
import { Link } from 'gatsby';
import Typography from '../elements/Typography';

const SubNavLink: FC<{ children: ReactNode }> = ({ children, to, onClick }) => {
  return (
    <Link
      className="h-full w-full lg:w-auto flex items-center px-6 py-4 text-night-blue hover:text-night-blue hover:border-night-blue border-transparent border-b-2 cursor-pointer"
      activeClassName="border-b-2 border-yellow"
      to={to}
      onClick={onClick}
    >
      <Typography variant="smaller" element="span">
        {children}
      </Typography>
    </Link>
  );
};

export default SubNavLink;
