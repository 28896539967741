import React, { FC } from 'react';
import Image from 'gatsby-plugin-sanity-image';

interface GenericImageProps {
  src: any;
  width?: number;
  height?: number;
  className?: string;
  style?: any;
  alt?: string;
}

const GenericImage: FC<GenericImageProps> = ({ src, width, height, className, style, alt }) => {
  return (
    <Image
      // pass asset, hotspot, and crop fields
      {...src}
      // tell Sanity how large to make the image (does not set any CSS)
      alt={alt || 'kuva sivustolla'}
      className={className}
      width={width || 500}
      height={height || ''}
      // style it how you want it
      style={style}
    />
  );
};

export default GenericImage;
