import React, { FC } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import Chevron from '../../../../common/icons/Chevron';
import SubNavLink from '../../../../common/actions/SubNavLink';
import Typography from '../../../../common/elements/Typography';
import { useNavigationAPI, useNavigationState } from '../../../../../context/NavigationContext';

const SubDropdown: FC = () => {
  const { closeSubDropdown } = useNavigationAPI();
  const { activeSubDropdown, subDropdownOpen, subdropdownContent } = useNavigationState();
  // if (!subdropdownContent) return null;

  return (
    <motion.div
      {...subdropdownMotion}
      style={{ top: '3.1rem' }}
      className="absolute w-full right-0 left-0 top-12 bg-gray-100 border-gray-200 border-t overflow-hidden"
    >
      <div className="flex flex-col items-left lg:items-end lg:flex-row w-full lg:mx-10 3xl:max-w-screen-3xl">
        <div
          className="group/subdropheading h-full w-full lg:w-auto flex items-center px-6 py-4 text-night-blue night-blue border-transparent border-b-2 cursor-pointer"
          onClick={() => closeSubDropdown()}
        >
          <Typography variant="smaller" element="span" className="mr-2">
            {activeSubDropdown?.title}
          </Typography>

          <span
            className={clsx(
              subDropdownOpen && 'transition transform rotate-90 lg:rotate-0',
              'group-hover/subdropheading:lg:-rotate-90 group-hover/subdropheading:fill-red-400'
            )}
          >
            <Chevron />
          </span>
        </div>
        {activeSubDropdown?.children.map(({ title, target }, i) => (
          <SubNavLink key={`subNavLink_${i}`} to={`/${target.slug.current}`}>
            {title}
          </SubNavLink>
        ))}
      </div>
    </motion.div>
  );
};

export default SubDropdown;

const subdropdownMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};
