import React, { FC } from 'react';
import Image from 'gatsby-plugin-sanity-image';

const Logo: FC<{ src: any }> = ({ src }) => {
  return (
    <Image
      {...src}
      width={220}
      alt="logo"
      style={{
        width: '220px',
        height: '100%',
        objectFit: 'contain',
      }}
    />
  );
};

export default Logo;
