import React, { ElementType, FC } from 'react';
import clsx from 'clsx';
import { RiArrowDropRightLine } from 'react-icons/ri';
import Typography from '../../../../common/elements/Typography';

interface NavItemElProps {
  renderAs: ElementType | string;
  title: string;
  hasChildren?: boolean;
  dropdownOpen?: boolean;
}

export const NavItemEl: FC<NavItemElProps> = ({ renderAs, dropdownOpen, hasChildren, ...props }) => {
  const Element = renderAs;
  return (
    <Element
      {...props}
      className={clsx(
        'flex items-center justify-start lg:justify-center flex-wrap px-6 py-7',
        'text-white hover:text-yellow border-b-2 hover:border-yellow border-transparent',
        hasChildren && dropdownOpen && 'border-yellow'
      )}
    >
      <Typography
        variant="smaller"
        element="span"
        className="font-sans font-light text-xxs sm:text-xs uppercase tracking-small"
      >
        {props.title}
      </Typography>
      {hasChildren && <RiArrowDropRightLine size="16px" className={clsx(dropdownOpen && 'transform rotate-90')} />}
    </Element>
  );
};
