import { graphql, useStaticQuery } from 'gatsby';

const useFooterNavigationQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      navigation_konserni: sanityNavigation(name: { eq: "footer-navigation-konserni" }) {
        ...footerNavigationFragment
      }
      navigation_toimialat: sanityNavigation(name: { eq: "footer-navigation-toimialat" }) {
        ...footerNavigationFragment
      }
      navigation_bottom: sanityNavigation(name: { eq: "footer-bottom-navigation" }) {
        ...footerNavigationFragment
      }
    }
  `);

  return data;
};

export default useFooterNavigationQuery;
