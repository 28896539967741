import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useBoolean } from 'usehooks-ts';

interface NavigationAPI {
  openSubDropdown: () => void;
  closeSubDropdown: () => void;
  openDropdown: () => void;
  closeDropdown: () => void;
  setActiveSubDropdown: (subDropdown: string | null) => void;
}
interface NavigationState {
  subDropdownOpen: boolean;
  dropdownOpen: boolean;
  activeSubDropdown: any | null;
}

const NavigationStateContext = createContext<NavigationState>({} as NavigationState);
const NavigationAPIContext = createContext<NavigationAPI>({} as NavigationAPI);

const NavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const { value: dropdownOpen, setTrue: openDropdown, setFalse: closeDropdown } = useBoolean(false);
  const { value: subDropdownOpen, setTrue: openSubDropdown, setFalse: closeSubDropdown } = useBoolean(false);
  const [dropdownContent, setDropdownContent] = useState<ReactNode>(null);
  const [activeSubDropdown, setActiveSubDropdown] = useState<string | null>(null);

  const navigationStateValue = useMemo(
    () => ({ subDropdownOpen, dropdownOpen, activeSubDropdown, dropdownContent }),
    [subDropdownOpen, dropdownOpen, activeSubDropdown, dropdownContent]
  );
  const navigationAPIValue = useMemo(
    () => ({
      openDropdown,
      closeDropdown,
      setDropdownContent,
      openSubDropdown,
      closeSubDropdown,
      setActiveSubDropdown,
    }),
    [openSubDropdown, closeSubDropdown, openDropdown, closeDropdown, setActiveSubDropdown, setDropdownContent]
  );

  return (
    <NavigationAPIContext.Provider value={navigationAPIValue}>
      <NavigationStateContext.Provider value={navigationStateValue}>{children}</NavigationStateContext.Provider>
    </NavigationAPIContext.Provider>
  );
};

const useNavigationAPI = () => {
  const context = useContext(NavigationAPIContext);
  if (context === undefined) {
    throw new Error('useNavigationAPI must be used within a NavigationContextProvider');
  }
  return context;
};

const useNavigationState = () => {
  const context = useContext(NavigationStateContext);
  if (context === undefined) {
    throw new Error('useNavigationState must be used within a NavigationContextProvider');
  }
  return context;
};

export { NavigationContextProvider, useNavigationState, useNavigationAPI };
