import React, { FC } from 'react';
import CookieConsent from 'react-cookie-consent';

const consentContainer = 'flex flex-wrap justify-between bg-grey text-night-blue w-full fixed bottom-0 z-[999]';
const consentContent = 'flex-1 w-300px m-4';
const consentButton =
  'bg-yellow border-0 border-radius-0 box-shadow-none text-night-blue cursor-pointer flex-auto p-2 m-4';

const CustomCookieConsent: FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Hyväksyn"
      declineButtonText="En"
      containerClasses={consentContainer}
      contentClasses={consentContent}
      buttonClasses={consentButton}
    >
      Käytämme sivustolla evästeitä käyttäjäkokemuksen parantamiseksi. Selaamalla verkkosivustoa hyväksyt evästeiden
      käytön.
    </CookieConsent>
  );
};

export default CustomCookieConsent;
