import { graphql, useStaticQuery } from 'gatsby';

const useMainNavigationQuery = () => {
  const { navigation } = useStaticQuery(graphql`
    query {
      navigation: sanityNavigation(name: { eq: "main-navigation" }) {
        ...mainNavigationFragment
      }
    }
  `);

  return navigation;
};

export default useMainNavigationQuery;
