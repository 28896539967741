import React, { FC, ReactNode, useRef } from 'react';
import { motion } from 'framer-motion';
import { useOnClickOutside } from 'usehooks-ts';
import Dropdown from './Dropdown';
import SubDropdown from './SubDropdown';
import { useNavigationAPI, useNavigationState } from '../../../../../context/NavigationContext';
import clsx from 'clsx';

const DropdownContainer: FC = () => {
  const { dropdownOpen, subDropdownOpen, dropdownContent } = useNavigationState();
  const { closeSubDropdown, openDropdown, closeDropdown } = useNavigationAPI();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (dropdownOpen) closeDropdown();
    if (subDropdownOpen) closeSubDropdown();
  });
  if (!dropdownContent) return null;
  const { links, title } = dropdownContent;

  return (
    <>
      {dropdownOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          ref={ref}
          // style={{ height: 'calc(100% - 24px)' }}
          className={clsx(
            'top-0 h-[calc(100%-24px)] lg:h-auto',
            'absolute w-full lg:top-auto  lg:right-0 lg:left-0 lg:-bottom-[50px] bg-gray-100'
          )}
          // style={isMobile ? {} : { bottom: '-50px' }}
        >
          <DropdownWrapper>
            {dropdownOpen && <Dropdown links={links} title={title} />}
            {subDropdownOpen && <SubDropdown />}
          </DropdownWrapper>
        </motion.div>
      )}
    </>
  );
};

export default DropdownContainer;

const DropdownWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="relative">
      <div className="flex flex-col lg:flex-row w-full lg:w-auto lg:mx-10 3xl:max-w-screen-3xl">{children}</div>
    </div>
  );
};
