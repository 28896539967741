import React, { FC } from 'react';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import NavItem from './NavItem';
import DropdownContainer from './Dropdown/DropdownContainer';
import LanguageSwitch from '../../../common/actions/LanguageSwitchLegacy';
import useMainNavigationQuery from '../../../../hooks/queries/useMainNavigationQuery';

const MobileNavigation: FC = () => {
  const { sections }: Queries.SanityNavigation = useMainNavigationQuery();
  const { pathname } = useLocation();
  const isEnglish = pathname.includes('/en');

  return (
    <nav className={clsx('relative flex flex-col bg-navy-blue w-screen')}>
      {!isEnglish && sections?.map((section, i) => <NavItem key={`navItem_${i}`} {...section} />)}
      <LanguageSwitch />
      <DropdownContainer />
    </nav>
  );
};

export default MobileNavigation;
