import React, { FC } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Chevron from '../../../../common/icons/Chevron';
import Typography from '../../../../common/elements/Typography';
import { useNavigationAPI, useNavigationState } from '../../../../../context/NavigationContext';

const Dropdown: FC<{ title: string; links: any }> = ({ title, links }) => {
  const { openSubDropdown, closeSubDropdown, openDropdown, closeDropdown, setActiveSubDropdown } = useNavigationAPI();
  const { dropdownOpen, activeSubDropdown } = useNavigationState();

  return (
    <>
      <motion.div
        // {...dropdownHeadingMotion}
        className={clsx(
          'flex-row-reverse lg:flex-row gap-2 justify-end',
          'flex h-full w-full lg:w-auto items-center px-6 py-4',
          'text-night-blue night-blue border-transparent border-b-2 cursor-pointer'
        )}
        onClick={() => {
          closeDropdown();
          closeSubDropdown();
        }}
      >
        <Typography variant="smaller" element="span">
          {title}
        </Typography>

        <span className={`${dropdownOpen ? 'transition-transform transform rotate-180 lg:rotate-0' : ''}`}>
          <Chevron />
        </span>
      </motion.div>

      {links.map((link, i) => (
        <motion.div
          // {...dropdownItemMotion}
          key={`dropdownLink_${i}`}
          className={clsx(
            activeSubDropdown === link && 'border-b-2 border-yellow',
            'z-100 h-full w-full lg:w-auto flex items-left md:items-center px-6 py-4 text-night-blue hover:text-night-blue hover:border-night-blue border-transparent border-b-2 cursor-pointer'
          )}
          onClick={() => {
            setActiveSubDropdown(link);
            openSubDropdown();
          }}
        >
          <Typography variant="smaller" element="span">
            {link.title}
          </Typography>
        </motion.div>
      ))}
    </>
  );
};
export default Dropdown;

//animate when dropdown is closed and opened and on mobile only
// const dropdownMotion = {
//   initial: { opacity: 0, y: -250 },
//   animate: { opacity: 1, y: 0 },
//   transition: {
//     type: 'spring',
//     mass: 0.6,
//     stiffness: 75,
//     delay: 0.2,
//   },
// };

// const dropdownHeadingMotion = {
//   initial: { y: -250 },
//   animate: { y: 0 },
//   transition: {
//     type: 'spring',
//     mass: 0.6,
//     stiffness: 75,
//     delay: 0.2,
//   },
// };

// const dropdownItemMotion = {
//   initial: { y: -250 },
//   animate: { y: 0 },
//   transition: {
//     type: 'spring',
//     mass: 0.6,
//     stiffness: 75,
//     delay: 0.2,
//   },
// };
