import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';

interface ModalState {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const ModalStateContext = createContext<ModalState>({} as ModalState);

const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean(false);

  const modalValue = useMemo(() => ({ open, openModal, closeModal }), [open, openModal, closeModal]);

  return <ModalStateContext.Provider value={modalValue}>{children}</ModalStateContext.Provider>;
};

const useModal = () => {
  const context = useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContextProvider');
  }
  return context;
};

export { ModalContextProvider, useModal };
