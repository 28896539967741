import React, { FC, HTMLAttributes, createElement } from 'react';
import clsx from 'clsx';

type TypographyVariant =
  | 'title-main'
  | 'title'
  | 'heading-two'
  | 'heading-three'
  | 'heading-four'
  | 'subtitle'
  | 'subtitle-small'
  | 'emphasis'
  | 'body'
  | 'body-small'
  | 'small'
  | 'smaller'
  | 'smallest';

type Element =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'small'
  | 'div'
  | 'a'
  | 'li'
  | 'ul'
  | 'ol'
  | 'blockquote'
  | 'strong'
  | 'em'
  | 'label';

interface TypographyProps extends HTMLAttributes<HTMLDivElement> {
  variant: TypographyVariant;
  element: Element;
  children: React.ReactNode;
  className?: string;
}

const styles = {
  'title-main': 'font-sans font-bold text-3xl md:text-5xl 3xl:text-6xl tracking-title',
  title: 'font-sans font-extrabold text-xl md:text-2xl lg:text-4xl tracking-title',
  'heading-two': 'font-sans font-extrabold text-base sm:text-lg md:text-xl lg:text-2xl tracking-heading',
  'heading-three': 'font-sans font-extrabold text-base sm:text-lg tracking-heading',
  'heading-four': 'font-sans font-extrabold text-sm sm:text-base 3xl:text-lg tracking-heading',
  subtitle: 'font-sans font-normal text-base sm:text-xl tracking-heading',
  'subtitle-small': 'font-sans font-normal text-xl tracking-heading',
  emphasis: 'font-sans font-normal italic text-xl tracking-heading',
  body: 'font-sans font-normal text-sm sm:text-base',
  'body-small': 'font-sans font-normal text-xs sm:text-sm',
  small: 'font-sans font-normal text-xs sm:text-sm uppercase tracking-small',
  smaller: 'font-sans font-light text-xxs sm:text-xs uppercase tracking-small',
  smallest: 'font-sans font-light text-xxs uppercase tracking-small',
};
const Typography: FC<TypographyProps> = ({ variant, className, element, children, ...rest }) => {
  const classes = clsx(styles[variant], className);

  return createElement(element, { className: classes, ...rest }, children);
};

export default Typography;
