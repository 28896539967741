import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { FC, Fragment, ReactNode } from 'react';

const OVERLAY_STYLES = {
  center: {
    container: 'fixed inset-y-0 left-0 z-10 w-screen overflow-y-auto',
    style: 'items-center justify-center p-4',
  },
  top: {
    container: 'left-0 right-0 top-24 md:top-28 overflow-hidden',
    style: 'w-screen',
  },
};

type OverlayPosition = 'center' | 'top';

const Overlay: FC<{ children: ReactNode; position: OverlayPosition }> = ({ position = 'center', children }) => {
  return (
    <Transition.Child {...transitions.content[position]} as={Fragment}>
      <div className={clsx('fixed', OVERLAY_STYLES[position].container)}>
        <div className={clsx('flex min-h-full', OVERLAY_STYLES[position].style)}>
          <Dialog.Panel className="mx-auto">{children}</Dialog.Panel>
        </div>
      </div>
    </Transition.Child>
  );
};

export default Overlay;

const transitions = {
  content: {
    center: {
      enter: 'ease-out duration-300',
      enterFrom: 'opacity-0 scale-95',
      enterTo: 'opacity-100 scale-100',
      leave: 'ease-in duration-200',
      leaveFrom: 'opacity-100 scale-100',
      leaveTo: 'opacity-0 scale-95',
    },
    top: {
      enter: 'ease-out duration-300',
      enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
      enterTo: 'opacity-100 translate-y-0 sm:scale-100',
      leave: 'ease-in duration-200',
      leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
      leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
    },
  },
};
