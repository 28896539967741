import React, { FC } from 'react';
import MobileNavigation from './MobileNavigation';
import Modal from '../../../common/actions/Modal';
import Hamburger from '../../../common/elements/Hamburger';
import { useModal } from '../../../../context/ModalContext';

const Sidebar: FC = () => {
  const { open, openModal, closeModal } = useModal();
  return (
    <Modal
      position="top"
      renderButton={
        <Hamburger
          open={open}
          onClick={() => {
            open ? closeModal() : openModal();
          }}
        />
      }
    >
      <MobileNavigation />
    </Modal>
  );
};

export default Sidebar;
