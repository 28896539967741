import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import Layout from './src/components/layout';
import '@fontsource/archivo';
import '@fontsource/archivo-black';
import './src/styles/global.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
