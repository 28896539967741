import React, { FC } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import NavItem from './NavItem';
import DropdownContainer from './Dropdown/DropdownContainer';
import { MAIN_NAV_MOTION } from '../../../../constants/motion';
import useMainNavigationQuery from '../../../../hooks/queries/useMainNavigationQuery';
import { useHeaderAPI, useHeaderState } from '../../../../context/HeaderContext';
import LanguageSwitch from '../../../common/actions/LanguageSwitchLegacy';

const Navigation: FC = () => {
  const { sections }: Queries.SanityNavigation = useMainNavigationQuery();
  const { pathname } = useLocation();
  const isEnglish = pathname.includes('/en');

  return (
    <motion.nav
      {...MAIN_NAV_MOTION}
      className={clsx(
        'lg:flex flex-col lg:flex-row lg:flex-wrap justify-end bg-navy-blue lg:bg-transparent lg:h-full w-full'
      )}
    >
      {!isEnglish && sections?.map((section, i) => <NavItem key={`navItem_${i}`} {...section} />)}
      <LanguageSwitch />
      <DropdownContainer />
    </motion.nav>
  );
};
export default Navigation;
