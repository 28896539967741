import React, { FC } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { IconContext } from 'react-icons';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import Logo from '../../common/image/Logo';
import Typography from '../../common/elements/Typography';
import GenericImage from '../../common/image/GenericImage';
import useFooterNavigationQuery from '../../../hooks/queries/useFooterNavigationQuery';
import useSiteConfigQuery from '../../../hooks/queries/useSiteConfigQuery';

const Footer: FC = () => {
  const { logo, bg_icon } = useSiteConfigQuery();
  const { pathname } = useLocation();
  const isEnglish = pathname.includes('/en');
  const { navigation_toimialat, navigation_konserni, navigation_bottom } = useFooterNavigationQuery();
  return (
    <footer className="shadow-md bg-night-blue relative justify-center items-start overflow-hidden">
      <GenericImage
        width={320}
        style={{
          width: '20rem',
          objectFit: 'contain',
          filter: 'opacity(0.26)',
          top: '0rem',
          left: '1rem',
          position: 'absolute',
          pointerEvents: 'none',
        }}
        src={bg_icon}
      />
      <div className=" flex flex-row lg:flex-row justify-between flex-wrap gap-4 py-10 px-4 lg:container mx-auto">
        <div className="text-white w-full lg:w-auto lg:mb-0">
          <Logo src={logo} />
        </div>
        {!isEnglish && (
          <div className="flex w-full flex-wrap sm:flex-nowrap lg:w-auto gap-4 md:gap-10 lg:gap-20">
            <FooterMenu {...navigation_toimialat} />
            <FooterMenu {...navigation_konserni} />

            <div className="flex flex-col w-full sm:w-1/3 lg:w-auto text-white small uppercase">
              <Typography variant="heading-four" element="h4" className="mb-1">
                Osoite
              </Typography>
              <a>
                <Typography variant="smaller" element="span">
                  Rautatehtaankatu 22
                </Typography>
              </a>
              <a>
                <Typography variant="smaller" element="span">
                  20200 Turku
                </Typography>
              </a>
              <a>
                <Typography variant="smaller" element="span">
                  Suomi Finland
                </Typography>
              </a>
            </div>
          </div>
        )}
      </div>
      {!isEnglish && (
        <div className="flex lg:flex-row flex-col justify-between px-4 py-10">
          <FooterBottomMenu {...navigation_bottom} />
          <FooterSocialMenu />
        </div>
      )}
    </footer>
  );
};

export default Footer;

const FooterMenu: FC<Queries.SanityNavigation> = (props) => {
  const { title, sections } = props;
  return (
    <nav className="flex flex-col w-full sm:w-1/3 lg:w-auto text-white uppercase">
      <Typography variant="heading-four" element="h4" className="mb-1">
        {title}
      </Typography>
      {sections?.map((section, i) =>
        section?.externalUrl ? (
          <a
            key={`section_${i}`}
            href={section.externalUrl}
            target="_blank"
            rel="noreferrer"
            className="hover:text-yellow"
          >
            <Typography variant="smaller" element="span">
              {section.title}
            </Typography>
          </a>
        ) : (
          <Link
            key={`section_${i}`}
            className="hover:text-yellow"
            activeClassName="text-yellow"
            to={`/${section?.target?.slug?.current}`}
          >
            <Typography variant="smaller" element="span">
              {section?.title}
            </Typography>
          </Link>
        )
      )}
    </nav>
  );
};

const FooterBottomMenu: FC<Queries.SanityNavigation> = (props) => {
  const { sections } = props;
  return (
    <div className="flex flex-col md:flex-row pb-10 lg:pb-0 text-white md:items-end uppercase">
      {sections?.map((section, i) => (
        <Link
          key={`section_${i}`}
          className="hover:text-yellow"
          activeClassName="text-yellow"
          to={`/${section?.target?.slug?.current}`}
        >
          <Typography variant="smaller" element="span" className="md:mr-10 lg:mr-20">
            {section?.title}
          </Typography>
        </Link>
      ))}
    </div>
  );
};

const FooterSocialMenu: FC = () => {
  return (
    <div className="flex flex-col text-white">
      <Typography variant="smaller" element="span" className="smaller uppercase mb-2">
        Seuraa meitä
      </Typography>
      <IconContext.Provider value={{ color: '#0080bb', size: '2.2rem' }}>
        <div className="flex">
          <a href="https://www.facebook.com/lstgroupfi" target="_blank" rel="noreferrer">
            <FaFacebookSquare className="mr-4 transition duration-100 ease-in-out transform hover:scale-105" />
          </a>
          <a href="https://www.linkedin.com/company/lst-group-finland" target="_blank" rel="noreferrer">
            <FaLinkedin className="transition duration-100 ease-in-out transform hover:scale-105" />
          </a>
        </div>
      </IconContext.Provider>
    </div>
  );
};
