import React, { FC } from 'react';

const Chevron: FC = () => {
  return (
    <svg
      className="h-4 w-4 leading-none text-navy-blue"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
    </svg>
  );
};

export default Chevron;
