import React, { FC } from 'react';
import { Link as GLink } from 'gatsby';
import { NavItemEl as Element } from './NavItemEl';
import { useModal } from '../../../../../context/ModalContext';
import { useNavigationAPI, useNavigationState } from '../../../../../context/NavigationContext';

const NavItem: FC<Queries.SanityNavigationSection> = (props) => {
  const { links, title, externalUrl, target } = props;
  const hasChildren = links?.length;
  const isExternal = externalUrl;
  const isInternal = target;

  const { openDropdown, closeDropdown, setDropdownContent } = useNavigationAPI();
  const { closeModal: closeSidebar } = useModal();
  const { dropdownOpen } = useNavigationState();

  const hasChildrenProps = {
    renderAs: 'button',
    dropdownOpen: dropdownOpen,
    hasChildren: true,
    onClick: () => {
      !dropdownOpen && openDropdown();
      dropdownOpen && closeDropdown();
      !dropdownOpen && setDropdownContent({ links, title });
    },
  };

  const ExternalLinkProps = {
    renderAs: 'a',
    isExternal: true,
    title,
    href: externalUrl,
    target: '_blank',
    rel: 'noreferrer',
  };

  const InternalLinkProps = {
    isInternal: true,
    onClick: () => closeSidebar(),
    title: title,
    renderAs: GLink,
    to: `/${target?.slug?.current}`,
    activeClassName: 'text-yellow border-b-2 border-yellow',
  };

  if (hasChildren) return <Element {...hasChildrenProps} {...props} />;
  if (isExternal) return <Element {...ExternalLinkProps} />;
  if (isInternal) return <Element {...InternalLinkProps} />;

  return null;
};

export default NavItem;
