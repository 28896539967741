import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';

interface HeaderState {
  sidebarOpen: boolean;
}
interface HeaderAPI {
  openSidebar: () => void;
  closeSidebar: () => void;
}

const HeaderStateContext = createContext<HeaderState>({} as HeaderState);
const HeaderAPIContext = createContext<HeaderAPI>({} as HeaderAPI);

const HeaderContextProvider = ({ children }: { children: ReactNode }) => {
  const { value: sidebarOpen, setTrue: openSidebar, setFalse: closeSidebar } = useBoolean(false);

  const headerAPIValue = useMemo(() => ({ openSidebar, closeSidebar }), [openSidebar, closeSidebar]);
  const headerStateValue = useMemo(() => ({ sidebarOpen }), [sidebarOpen]);

  return (
    <HeaderAPIContext.Provider value={headerAPIValue}>
      <HeaderStateContext.Provider value={headerStateValue}>{children}</HeaderStateContext.Provider>
    </HeaderAPIContext.Provider>
  );
};

const useHeaderAPI = () => {
  const context = useContext(HeaderAPIContext);
  if (context === undefined) {
    throw new Error('useHeaderAPI must be used within a HeaderContextProvider');
  }
  return context;
};

const useHeaderState = () => {
  const context = useContext(HeaderStateContext);
  if (context === undefined) {
    throw new Error('useHeaderState must be used within a HeaderContextProvider');
  }
  return context;
};

export { HeaderContextProvider, useHeaderState, useHeaderAPI };
