import { Link } from 'gatsby';
import React, { FC } from 'react';
import { useLocation } from '@reach/router';

const LanguageSwitch: FC = () => {
  const { pathname } = useLocation();
  const isEnglish = pathname.includes('/en');

  return (
    <Link to={isEnglish ? '/' : '/en'} className="lg:absolute bg-gray-300 hover:bg-gray-200 px-6 py cursor-pointer">
      <span className="smaller text-night-blue hover:text-navy-blue">{isEnglish ? 'Suomeksi' : 'English'}</span>
    </Link>
  );
};

export default LanguageSwitch;
