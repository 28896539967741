import { graphql, useStaticQuery } from 'gatsby';

const useSiteConfigQuery = () => {
  const { config } = useStaticQuery(graphql`
    query {
      config: sanitySiteConfig(_type: { eq: "siteConfig" }) {
        ...siteConfigFragment
      }
    }
  `);

  return config;
};

export default useSiteConfigQuery;
