import React, { FC, Fragment, ReactElement, ReactNode, cloneElement, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Overlay from '../elements/Overlay';
import { useModal } from '../../../context/ModalContext';

const Modal: FC<{ children: ReactNode; position: string; renderButton: ReactElement }> = ({
  children,
  position = 'center',
  renderButton,
}) => {
  const { open, openModal, closeModal } = useModal();

  const buttonWithProps = renderButton
    ? cloneElement(renderButton, {
        onClick: () => openModal(),
      })
    : null;

  return (
    <>
      {buttonWithProps}
      <Transition show={open} as={Fragment}>
        <Dialog onClose={() => closeModal()} className={clsx('relative', position === 'top' ? 'z-30' : 'z-50')}>
          <Transition.Child {...transitions.backdrop} as={Fragment}>
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          </Transition.Child>
          <Overlay position={position}>{children}</Overlay>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;

const transitions = {
  backdrop: {
    enter: 'transition-opacity duration-300',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'transition-opacity duration-300',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0',
  },
};
