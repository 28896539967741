import React, { FC, ReactNode } from 'react';
import { Script } from 'gatsby';
import Footer from './Footer';
import Header from './Header';
import { HeaderContextProvider } from '../../context/HeaderContext';
import { NavigationContextProvider } from '../../context/NavigationContext';
import CustomCookieConsent from '../common/actions/CustomCookieConsent';
import { ModalContextProvider } from '../../context/ModalContext';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Script id="leadfeeder_tracker_script">{`(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('lYNOR8xY30N7WQJZ');`}</Script>
      <HeaderContextProvider>
        <NavigationContextProvider>
          <ModalContextProvider>
            <Header />
          </ModalContextProvider>
        </NavigationContextProvider>
      </HeaderContextProvider>
      <>{children}</>
      <Footer />
      <CustomCookieConsent />
    </>
  );
};

export default Layout;
