import clsx from 'clsx';
import React, { FC } from 'react';

const Hamburger: FC<{ open: boolean; onClick: () => void }> = ({ open, onClick }) => (
  <div className="flex items-center">
    <button className="lg:hidden text-white w-10 h-10 relative focus:outline-none bg-transparent" onClick={onClick}>
      <span className="sr-only">Avaa päävalikko</span>
      <div className="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
        <span
          aria-hidden="true"
          className={clsx(
            'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out',
            open ? 'rotate-45' : '-translate-y-1.5'
          )}
        />
        <span
          aria-hidden="true"
          className={clsx(
            'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out',
            open && 'opacity-0'
          )}
        />
        <span
          aria-hidden="true"
          className={clsx(
            'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out',
            open ? '-rotate-45' : 'translate-y-1.5'
          )}
        />
      </div>
    </button>
  </div>
);

export default Hamburger;
